import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';

import styles from './Item.module.scss';

const cx = classNames.bind(styles);

export default class Item extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {    
    this.setState({
      isOpen: nextProps.isOpen,
    });
  }

  render() {
    const { isOpen } = this.state;
    const { id, question, answer } = this.props.item;
    
    return (
      <div className={cx('container')}>
        <div className={cx(['header', { open: isOpen }])} onClick={this.toggle.bind(this, id)}>
          <div className={cx('title')}>
            <img src={require('assets/images/misc/qna-q.png')} alt="Q" width="24" height="24" className={cx('titleIcon')} />
            <div className={cx('titleText')}>{question}</div>
          </div>
          <Ionicon icon={this.arrowIcon(isOpen)} fontSize="17px" />
        </div>
        {isOpen && (
          <div className={cx('content')}>
            {answer.trim() !== '' && (
              <div className={cx('answer')}>
                <img src={require('assets/images/misc/qna-a.png')} alt="A" width="20" height="20" className={cx('answerIcon')} />
                {answer.split('\n').map((line, i) => {
                  return <span key={String(i)} className={cx('answerText')}>{line}<br/></span>;
                })}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  toggle = (id) => {
    const { isOpen } = this.state;

    if (isOpen) {
      navigate('/club-artisee/faq/');
    }
    else {
      navigate(`/club-artisee/faq/?${id}`);
    }
  }

  arrowIcon = (isOpen) => {
    return isOpen ? 'md-remove' : 'md-add';
  }
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
};

Item.defaultProps = {
  isOpen: false,
};