import React, { Component } from 'react';

import FAQ from 'components/Club/FAQ';
import PageTitleBar from 'components/misc/PageTitleBar';
import TabBar from 'components/misc/TabBar';

const tabBarMenus = [
  { name: '멤버십 안내', link: '/club-artisee/membership/' },
  { name: '마이 아티제', link: '/club-artisee/my-artisee/' },
  { name: '마이 페이지', link: '/club-artisee/my-page/' },
  { name: '자주 묻는 질문', link: '/club-artisee/faq/' },
];

class FAQPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
    };
  }

  componentDidMount() {
    this.setState({
      location: window.location,
    });
  }

  render() {
    const { location } = this.state;
    const id = location !== null ? location.search.substring(1) : null;

    return (
      <div>
        <PageTitleBar title="FAQ" backgroundImage={require('../../assets/images/banner/club_artisee.jpg')} />
        <TabBar index={3} data={tabBarMenus} />
        {id !== null && <FAQ id={id} />}
      </div>
    );
  }
}

export default FAQPage;
