import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Col } from 'react-grid-system';
import classNames from 'classnames/bind';

import styles from './index.module.scss';
import Item from './Item';
import { faqActions } from '../../../actions';

const cx = classNames.bind(styles);

class FAQ extends Component {

  componentDidMount() {
    if (this.props.faq.data.length === 0) {
      this.props.actions.faqActions.request();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure } = nextProps.faq;
    return !isProcessing && !failure;
  }

  render() {
    const { faq, id } = this.props;

    return (
      <div className={cx('container')}>
        <Container>
          <Row>
            <Col xs={12} lg={10} offset={{ lg: 1 }}>
              {faq.data.map((item, i) => {
                const isOpen = id === String(item.id);

                return (
                  <Item key={String(item.id)} item={item} isOpen={isOpen} />
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}


/**
 * Define redux settings
 */
const mapStateToProps = (state) => {
  const { faq } = state;
  return {
    faq,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      faqActions: bindActionCreators(faqActions, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);